import { Panel, PanelType, ChoiceGroup } from "@fluentui/react";
import * as React from "react";
import { useDirectories } from "../../../hooks/useDirectories";
export const DirectoryPickerPanel = ({ isOpen, dismissPanel, armToken, tenantId, switchTenant, }) => {
    const directories = useDirectories(armToken);
    return (React.createElement(Panel, { type: PanelType.medium, headerText: "Select Directory", isOpen: isOpen, onDismiss: dismissPanel, closeButtonAriaLabel: "Close" },
        React.createElement(ChoiceGroup, { options: directories.map((dir) => ({ key: dir.tenantId, text: `${dir.displayName} (${dir.tenantId})` })), selectedKey: tenantId, onChange: (event, option) => {
                switchTenant(option.key);
                dismissPanel();
            } })));
};
