import { Dropdown } from "@fluentui/react";
import * as React from "react";
export const SwitchSubscription = ({ subscriptions, setSelectedSubscriptionId, selectedSubscription, }) => {
    return (React.createElement(Dropdown, { label: "Subscription", className: "accountSwitchSubscriptionDropdown", options: subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.map((sub) => {
            return {
                key: sub.subscriptionId,
                text: sub.displayName,
                data: sub,
            };
        }), onChange: (_, option) => {
            setSelectedSubscriptionId(String(option === null || option === void 0 ? void 0 : option.key));
        }, defaultSelectedKey: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.subscriptionId, placeholder: subscriptions && subscriptions.length === 0 ? "No Subscriptions Found" : "Select a Subscription", styles: {
            callout: "accountSwitchSubscriptionDropdownMenu",
        } }));
};
