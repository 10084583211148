import * as DataModels from "../Contracts/DataModels";
export class DefaultExperienceUtility {
    static getApiKindFromDefaultExperience(defaultExperience) {
        if (!defaultExperience) {
            return DataModels.ApiKind.SQL;
        }
        switch (defaultExperience) {
            case "SQL":
                return DataModels.ApiKind.SQL;
            case "Mongo":
                return DataModels.ApiKind.MongoDB;
            case "Tables":
                return DataModels.ApiKind.Table;
            case "Cassandra":
                return DataModels.ApiKind.Cassandra;
            case "Gremlin":
                return DataModels.ApiKind.Graph;
            default:
                return DataModels.ApiKind.SQL;
        }
    }
    static getDefaultExperienceFromApiKind(apiKind) {
        if (apiKind == null) {
            return "SQL";
        }
        switch (apiKind) {
            case DataModels.ApiKind.SQL:
                return "SQL";
            case DataModels.ApiKind.MongoDB:
            case DataModels.ApiKind.MongoDBCompute:
                return "Mongo";
            case DataModels.ApiKind.Table:
                return "Tables";
            case DataModels.ApiKind.Cassandra:
                return "Cassandra";
            case DataModels.ApiKind.Graph:
                return "Gremlin";
            default:
                return "SQL";
        }
    }
}
