import { useEffect, useState } from "react";
import { initializeConfiguration } from "../ConfigContext";
// This hook initializes global configuration from a config.json file that is injected at deploy time
// This allows the same main Data Explorer build to be exactly the same in all clouds/platforms,
// but override some of the configuration as nesssary
export function useConfig() {
    const [state, setState] = useState();
    useEffect(() => {
        initializeConfiguration().then((response) => setState(response));
    }, []);
    return state;
}
