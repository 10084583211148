export var ApiKind;
(function (ApiKind) {
    ApiKind[ApiKind["SQL"] = 0] = "SQL";
    ApiKind[ApiKind["MongoDB"] = 1] = "MongoDB";
    ApiKind[ApiKind["Table"] = 2] = "Table";
    ApiKind[ApiKind["Cassandra"] = 3] = "Cassandra";
    ApiKind[ApiKind["Graph"] = 4] = "Graph";
    ApiKind[ApiKind["MongoDBCompute"] = 5] = "MongoDBCompute";
})(ApiKind || (ApiKind = {}));
export var ConflictResolutionMode;
(function (ConflictResolutionMode) {
    ConflictResolutionMode["Custom"] = "Custom";
    ConflictResolutionMode["LastWriterWins"] = "LastWriterWins";
})(ConflictResolutionMode || (ConflictResolutionMode = {}));
export var SparkClusterEndpointKind;
(function (SparkClusterEndpointKind) {
    SparkClusterEndpointKind["SparkUI"] = "SparkUI";
    SparkClusterEndpointKind["HistoryServerUI"] = "HistoryServerUI";
    SparkClusterEndpointKind["Livy"] = "Livy";
})(SparkClusterEndpointKind || (SparkClusterEndpointKind = {}));
export var PhoenixErrorType;
(function (PhoenixErrorType) {
    PhoenixErrorType["MaxAllocationTimeExceeded"] = "MaxAllocationTimeExceeded";
    PhoenixErrorType["MaxDbAccountsPerUserExceeded"] = "MaxDbAccountsPerUserExceeded";
    PhoenixErrorType["MaxUsersPerDbAccountExceeded"] = "MaxUsersPerDbAccountExceeded";
    PhoenixErrorType["AllocationValidationResult"] = "AllocationValidationResult";
    PhoenixErrorType["RegionNotServicable"] = "RegionNotServicable";
    PhoenixErrorType["SubscriptionNotAllowed"] = "SubscriptionNotAllowed";
    PhoenixErrorType["UnknownError"] = "UnknownError";
    PhoenixErrorType["PhoenixFlightFallback"] = "PhoenixFlightFallback";
    PhoenixErrorType["UserMissingPermissionsError"] = "UserMissingPermissionsError";
})(PhoenixErrorType || (PhoenixErrorType = {}));
