import { DefaultButton, DirectionalHint, FocusZone, Persona, PersonaInitialsColor, PersonaSize } from "@fluentui/react";
import * as React from "react";
import { useGraphPhoto } from "../../../hooks/useGraphPhoto";
export const MeControl = ({ openPanel, logout, account, graphToken }) => {
    const photo = useGraphPhoto(graphToken);
    return (React.createElement(FocusZone, null,
        React.createElement(DefaultButton, { id: "mecontrolHeader", className: "mecontrolHeaderButton", menuProps: {
                className: "mecontrolContextualMenu",
                isBeakVisible: false,
                directionalHintFixed: true,
                directionalHint: DirectionalHint.bottomRightEdge,
                calloutProps: {
                    minPagePadding: 0,
                },
                items: [
                    {
                        key: "SwitchDirectory",
                        text: "Switch Directory",
                        onClick: openPanel,
                    },
                    {
                        key: "SignOut",
                        text: "Sign Out",
                        onClick: logout,
                    },
                ],
            }, styles: {
                rootHovered: { backgroundColor: "#393939" },
                rootFocused: { backgroundColor: "#393939" },
                rootPressed: { backgroundColor: "#393939" },
                rootExpanded: { backgroundColor: "#393939" },
            } },
            React.createElement(Persona, { imageUrl: photo, text: account === null || account === void 0 ? void 0 : account.name, secondaryText: account === null || account === void 0 ? void 0 : account.username, showSecondaryText: true, showInitialsUntilImageLoads: true, initialsColor: PersonaInitialsColor.teal, size: PersonaSize.size28, className: "mecontrolHeaderPersona" }))));
};
