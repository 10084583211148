import { HttpHeaders } from "Common/Constants";
import useSWR from "swr";
import { configContext } from "../ConfigContext";
export async function fetchDatabaseAccounts(subscriptionId, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    let accounts = [];
    const apiVersion = "2023-09-15-preview";
    let nextLink = `${configContext.ARM_ENDPOINT}/subscriptions/${subscriptionId}/providers/Microsoft.DocumentDB/databaseAccounts?api-version=${apiVersion}`;
    while (nextLink) {
        const response = await fetch(nextLink, { headers });
        const result = response.status === 204 || response.status === 304 ? undefined : await response.json();
        if (!response.ok) {
            throw result;
        }
        nextLink = result.nextLink;
        accounts = [...accounts, ...result.value];
    }
    return accounts.sort((a, b) => a.name.localeCompare(b.name));
}
export async function fetchDatabaseAccountsFromGraph(subscriptionId, accessToken) {
    var _a;
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append(HttpHeaders.contentType, "application/json");
    const databaseAccountsQuery = "resources | where type =~ 'microsoft.documentdb/databaseaccounts'";
    const apiVersion = "2021-03-01";
    const managementResourceGraphAPIURL = `${configContext.ARM_ENDPOINT}providers/Microsoft.ResourceGraph/resources?api-version=${apiVersion}`;
    const databaseAccounts = [];
    let skipToken;
    do {
        const body = Object.assign({ query: databaseAccountsQuery, subscriptions: [subscriptionId] }, (skipToken
            ? {
                options: {
                    $skipToken: skipToken,
                },
            }
            : {
                options: {
                    $top: 150,
                },
            }));
        const response = await fetch(managementResourceGraphAPIURL, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const queryResponse = (await response.json());
        skipToken = queryResponse.$skipToken;
        (_a = queryResponse.data) === null || _a === void 0 ? void 0 : _a.map((databaseAccount) => {
            databaseAccounts.push(databaseAccount);
        });
    } while (skipToken);
    return databaseAccounts.sort((a, b) => a.name.localeCompare(b.name));
}
export function useDatabaseAccounts(subscriptionId, armToken) {
    const { data } = useSWR(() => (armToken && subscriptionId ? ["databaseAccounts", subscriptionId, armToken] : undefined), (_, subscriptionId, armToken) => fetchDatabaseAccountsFromGraph(subscriptionId, armToken));
    return data;
}
