import { Action } from "Shared/Telemetry/TelemetryConstants";
import { traceOpen } from "Shared/Telemetry/TelemetryProcessor";
import { useCarousel } from "hooks/useCarousel";
import { usePostgres } from "hooks/usePostgres";
import { extractFeatures } from "./Platform/Hosted/extractFeatures";
import { CollectionCreation, CollectionCreationDefaults } from "./Shared/Constants";
const ONE_WEEK_IN_MS = 604800000;
const features = extractFeatures();
const userContext = {
    apiType: "SQL",
    hasWriteAccess: true,
    isTryCosmosDBSubscription: false,
    portalEnv: "prod",
    features,
    subscriptionType: CollectionCreation.DefaultSubscriptionType,
    collectionCreationDefaults: CollectionCreationDefaults,
};
export function isAccountNewerThanThresholdInMs(createdAt, threshold) {
    let createdAtMs = Date.parse(createdAt);
    if (isNaN(createdAtMs)) {
        createdAtMs = 0;
    }
    const nowMs = Date.now();
    const millisecsSinceAccountCreation = nowMs - createdAtMs;
    return threshold > millisecsSinceAccountCreation;
}
function updateUserContext(newContext) {
    var _a, _b;
    if (newContext.databaseAccount) {
        newContext.apiType = apiType(newContext.databaseAccount);
        const isNewAccount = isAccountNewerThanThresholdInMs(((_b = (_a = newContext.databaseAccount) === null || _a === void 0 ? void 0 : _a.systemData) === null || _b === void 0 ? void 0 : _b.createdAt) || "", ONE_WEEK_IN_MS);
        if (!localStorage.getItem(newContext.databaseAccount.id)) {
            if (newContext.isTryCosmosDBSubscription || isNewAccount) {
                if (newContext.apiType === "Postgres" && !newContext.isReplica) {
                    usePostgres.getState().setShowResetPasswordBubble(true);
                    usePostgres.getState().setShowPostgreTeachingBubble(true);
                }
                else {
                    useCarousel.getState().setShouldOpen(true);
                    localStorage.setItem(newContext.databaseAccount.id, "true");
                    traceOpen(Action.OpenCarousel);
                }
            }
            else if (newContext.apiType === "Postgres") {
                usePostgres.getState().setShowPostgreTeachingBubble(true);
                localStorage.setItem(newContext.databaseAccount.id, "true");
            }
        }
    }
    Object.assign(userContext, newContext);
}
function apiType(account) {
    var _a;
    if (!account) {
        return "SQL";
    }
    const capabilities = (_a = account.properties) === null || _a === void 0 ? void 0 : _a.capabilities;
    if (capabilities) {
        if (capabilities.find((c) => c.name === "EnableCassandra")) {
            return "Cassandra";
        }
        if (capabilities.find((c) => c.name === "EnableGremlin")) {
            return "Gremlin";
        }
        if (capabilities.find((c) => c.name === "EnableMongo")) {
            return "Mongo";
        }
        if (capabilities.find((c) => c.name === "EnableTable")) {
            return "Tables";
        }
    }
    if (account.kind === "MongoDB" || account.kind === "Parse") {
        return "Mongo";
    }
    if (account.kind === "Postgres") {
        return "Postgres";
    }
    if (account.kind === "VCoreMongo") {
        return "VCoreMongo";
    }
    return "SQL";
}
export { updateUserContext, userContext };
