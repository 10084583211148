export function stripSpacesFromString(inputString) {
    if (inputString === undefined || typeof inputString !== "string") {
        return inputString;
    }
    return inputString.replace(/ /g, "");
}
/**
 * Implementation of endsWith which works for IE
 * @param stringToTest
 * @param suffix
 */
export function endsWith(stringToTest, suffix) {
    return stringToTest.indexOf(suffix, stringToTest.length - suffix.length) !== -1;
}
export function startsWith(stringToTest, prefix) {
    return stringToTest.indexOf(prefix) === 0;
}
