import { initializeIcons } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { AadAuthorizationFailure } from "Platform/Hosted/Components/AadAuthorizationFailure";
import * as React from "react";
import { render } from "react-dom";
import ChevronRight from "../images/chevron-right.svg";
import "../less/hostedexplorer.less";
import { AuthType } from "./AuthType";
import "./Explorer/Menus/NavBar/MeControlComponent.less";
import { AccountSwitcher } from "./Platform/Hosted/Components/AccountSwitcher";
import { ConnectExplorer } from "./Platform/Hosted/Components/ConnectExplorer";
import { DirectoryPickerPanel } from "./Platform/Hosted/Components/DirectoryPickerPanel";
import { FeedbackCommandButton } from "./Platform/Hosted/Components/FeedbackCommandButton";
import { MeControl } from "./Platform/Hosted/Components/MeControl";
import { SignInButton } from "./Platform/Hosted/Components/SignInButton";
import "./Platform/Hosted/ConnectScreen.less";
import { extractMasterKeyfromConnectionString } from "./Platform/Hosted/HostedUtils";
import "./Shared/appInsights";
import { useAADAuth } from "./hooks/useAADAuth";
import { useConfig } from "./hooks/useConfig";
import { useTokenMetadata } from "./hooks/usePortalAccessToken";
initializeIcons();
const App = () => {
    // For handling encrypted portal tokens sent via query paramter
    const params = new URLSearchParams(window.location.search);
    const [encryptedToken, setEncryptedToken] = React.useState(params && params.get("key"));
    const encryptedTokenMetadata = useTokenMetadata(encryptedToken);
    // For showing/hiding panel
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const config = useConfig();
    const { isLoggedIn, armToken, graphToken, account, tenantId, logout, login, switchTenant, authFailure } = useAADAuth();
    const [databaseAccount, setDatabaseAccount] = React.useState();
    const [authType, setAuthType] = React.useState(encryptedToken ? AuthType.EncryptedToken : undefined);
    const [connectionString, setConnectionString] = React.useState();
    const ref = React.useRef();
    React.useEffect(() => {
        // If ref.current is undefined no iframe has been rendered
        if (ref.current) {
            // In hosted mode, we can set global properties directly on the child iframe.
            // This is not possible in the portal where the iframes have different origins
            const frameWindow = ref.current.contentWindow;
            // AAD authenticated uses ALWAYS using AAD authType
            if (isLoggedIn) {
                frameWindow.hostedConfig = {
                    authType: AuthType.AAD,
                    databaseAccount,
                    authorizationToken: armToken,
                };
            }
            else if (authType === AuthType.EncryptedToken) {
                frameWindow.hostedConfig = {
                    authType: AuthType.EncryptedToken,
                    encryptedToken,
                    encryptedTokenMetadata,
                };
            }
            else if (authType === AuthType.ConnectionString) {
                frameWindow.hostedConfig = {
                    authType: AuthType.ConnectionString,
                    encryptedToken,
                    encryptedTokenMetadata,
                    masterKey: extractMasterKeyfromConnectionString(connectionString),
                };
            }
            else if (authType === AuthType.ResourceToken) {
                frameWindow.hostedConfig = {
                    authType: AuthType.ResourceToken,
                    resourceToken: connectionString,
                };
            }
        }
    });
    const showExplorer = (config && isLoggedIn && databaseAccount) ||
        (encryptedTokenMetadata && encryptedTokenMetadata) ||
        (authType === AuthType.ResourceToken && connectionString);
    return (React.createElement(React.Fragment, null,
        React.createElement("header", null,
            React.createElement("div", { className: "items", role: "menubar" },
                React.createElement("div", { className: "cosmosDBTitle" },
                    React.createElement("span", { className: "title", onClick: () => window.open("https://portal.azure.com", "_blank"), tabIndex: 0, title: "Go to Azure Portal" }, "Microsoft Azure"),
                    React.createElement("span", { className: "accontSplitter" }),
                    " ",
                    React.createElement("span", { className: "serviceTitle" }, "Cosmos DB"),
                    (isLoggedIn || (encryptedTokenMetadata === null || encryptedTokenMetadata === void 0 ? void 0 : encryptedTokenMetadata.accountName)) && (React.createElement("img", { className: "chevronRight", src: ChevronRight, alt: "account separator" })),
                    isLoggedIn && (React.createElement("span", { className: "accountSwitchComponentContainer" },
                        React.createElement(AccountSwitcher, { armToken: armToken, setDatabaseAccount: setDatabaseAccount }))),
                    !isLoggedIn && (encryptedTokenMetadata === null || encryptedTokenMetadata === void 0 ? void 0 : encryptedTokenMetadata.accountName) && (React.createElement("span", { className: "accountSwitchComponentContainer" },
                        React.createElement("span", { className: "accountNameHeader" }, encryptedTokenMetadata === null || encryptedTokenMetadata === void 0 ? void 0 : encryptedTokenMetadata.accountName)))),
                React.createElement(FeedbackCommandButton, null),
                React.createElement("div", { className: "meControl" }, isLoggedIn ? (React.createElement(MeControl, Object.assign({}, { graphToken, openPanel, logout, account }))) : (React.createElement(SignInButton, Object.assign({}, { login })))))),
        showExplorer && (
        // Ideally we would import and render data explorer like any other React component, however
        // because it still has a significant amount of Knockout code, this would lead to memory leaks.
        // Knockout does not have a way to tear down all of its binding and listeners with a single method.
        // It's possible this can be changed once all knockout code has been removed.
        React.createElement("iframe", { 
            // Setting key is needed so React will re-render this element on any account change
            key: (databaseAccount === null || databaseAccount === void 0 ? void 0 : databaseAccount.id) || (encryptedTokenMetadata === null || encryptedTokenMetadata === void 0 ? void 0 : encryptedTokenMetadata.accountName) || authType, ref: ref, "data-test": "DataExplorerFrame", id: "explorerMenu", name: "explorer", className: "iframe", title: "explorer", src: "explorer.html?v=1.0.1&platform=Hosted" })),
        !isLoggedIn && !encryptedTokenMetadata && (React.createElement(ConnectExplorer, Object.assign({}, { login, setEncryptedToken, setAuthType, connectionString, setConnectionString }))),
        isLoggedIn && authFailure && React.createElement(AadAuthorizationFailure, Object.assign({}, { authFailure })),
        isLoggedIn && !authFailure && (React.createElement(DirectoryPickerPanel, Object.assign({}, { isOpen, dismissPanel, armToken, tenantId, switchTenant })))));
};
render(React.createElement(App, null), document.getElementById("App"));
