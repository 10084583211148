export class CodeOfConductEndpoints {
}
CodeOfConductEndpoints.privacyStatement = "https://aka.ms/ms-privacy-policy";
CodeOfConductEndpoints.codeOfConduct = "https://aka.ms/cosmos-code-of-conduct";
CodeOfConductEndpoints.termsOfUse = "https://aka.ms/ms-terms-of-use";
export class EndpointsRegex {
}
EndpointsRegex.cassandra = [
    "AccountEndpoint=(.*).cassandra.cosmosdb.azure.com",
    "HostName=(.*).cassandra.cosmos.azure.com",
];
EndpointsRegex.mongo = "mongodb://.*:(.*)@(.*).documents.azure.com";
EndpointsRegex.mongoCompute = "mongodb://.*:(.*)@(.*).mongo.cosmos.azure.com";
EndpointsRegex.sql = "AccountEndpoint=https://(.*).documents.azure.com";
EndpointsRegex.table = "TableEndpoint=https://(.*).table.cosmosdb.azure.com";
export class ApiEndpoints {
}
ApiEndpoints.runtimeProxy = "/api/RuntimeProxy";
ApiEndpoints.guestRuntimeProxy = "/api/guest/RuntimeProxy";
export class ServerIds {
}
ServerIds.localhost = "localhost";
ServerIds.blackforest = "blackforest";
ServerIds.fairfax = "fairfax";
ServerIds.mooncake = "mooncake";
ServerIds.productionPortal = "prod";
ServerIds.dev = "dev";
export class ArmApiVersions {
}
ArmApiVersions.documentDB = "2015-11-06";
ArmApiVersions.arcadia = "2019-06-01-preview";
ArmApiVersions.arcadiaLivy = "2019-11-01-preview";
ArmApiVersions.arm = "2015-11-01";
ArmApiVersions.armFeatures = "2014-08-01-preview";
ArmApiVersions.publicVersion = "2020-04-01";
export class ArmResourceTypes {
}
ArmResourceTypes.notebookWorkspaces = "Microsoft.DocumentDB/databaseAccounts/notebookWorkspaces";
ArmResourceTypes.synapseWorkspaces = "Microsoft.Synapse/workspaces";
export class BackendDefaults {
}
BackendDefaults.partitionKeyKind = "Hash";
BackendDefaults.partitionKeyMultiHash = "MultiHash";
BackendDefaults.maxNumMultiHashPartition = 2;
BackendDefaults.singlePartitionStorageInGb = "10";
BackendDefaults.multiPartitionStorageInGb = "100";
BackendDefaults.maxChangeFeedRetentionDuration = 10;
BackendDefaults.partitionKeyVersion = 2;
export class ClientDefaults {
}
ClientDefaults.requestTimeoutMs = 60000;
ClientDefaults.portalCacheTimeoutMs = 10000;
ClientDefaults.errorNotificationTimeoutMs = 5000;
ClientDefaults.copyHelperTimeoutMs = 2000;
ClientDefaults.waitForDOMElementMs = 500;
ClientDefaults.cacheBustingTimeoutMs = 10 /** minutes **/ * 60 /** to seconds **/ * 1000 /** to milliseconds **/;
ClientDefaults.databaseThroughputIncreaseFactor = 100;
ClientDefaults.arcadiaTokenRefreshInterval = 20 /** minutes **/ * 60 /** to seconds **/ * 1000 /** to milliseconds **/;
ClientDefaults.arcadiaTokenRefreshIntervalPaddingMs = 2000;
export var AccountKind;
(function (AccountKind) {
    AccountKind["DocumentDB"] = "DocumentDB";
    AccountKind["MongoDB"] = "MongoDB";
    AccountKind["Parse"] = "Parse";
    AccountKind["GlobalDocumentDB"] = "GlobalDocumentDB";
    AccountKind["Default"] = "DocumentDB";
})(AccountKind || (AccountKind = {}));
export class CorrelationBackend {
}
CorrelationBackend.Url = "https://aka.ms/cosmosdbanalytics";
export class CapabilityNames {
}
CapabilityNames.EnableTable = "EnableTable";
CapabilityNames.EnableGremlin = "EnableGremlin";
CapabilityNames.EnableCassandra = "EnableCassandra";
CapabilityNames.EnableAutoScale = "EnableAutoScale";
CapabilityNames.EnableNotebooks = "EnableNotebooks";
CapabilityNames.EnableStorageAnalytics = "EnableStorageAnalytics";
CapabilityNames.EnableMongo = "EnableMongo";
CapabilityNames.EnableServerless = "EnableServerless";
CapabilityNames.EnableNoSQLVectorSearch = "EnableNoSQLVectorSearch";
export var CapacityMode;
(function (CapacityMode) {
    CapacityMode["Provisioned"] = "Provisioned";
    CapacityMode["Serverless"] = "Serverless";
})(CapacityMode || (CapacityMode = {}));
// flight names returned from the portal are always lowercase
export class Flights {
}
Flights.SettingsV2 = "settingsv2";
Flights.MongoIndexEditor = "mongoindexeditor";
Flights.MongoIndexing = "mongoindexing";
Flights.AutoscaleTest = "autoscaletest";
Flights.PartitionKeyTest = "partitionkeytest";
Flights.PKPartitionKeyTest = "pkpartitionkeytest";
Flights.PhoenixNotebooks = "phoenixnotebooks";
Flights.PhoenixFeatures = "phoenixfeatures";
Flights.NotebooksDownBanner = "notebooksdownbanner";
Flights.PublicGallery = "publicgallery";
export class AfecFeatures {
}
AfecFeatures.Spark = "spark-public-preview";
AfecFeatures.Notebooks = "sparknotebooks-public-preview";
AfecFeatures.StorageAnalytics = "storageanalytics-public-preview";
export class TagNames {
}
TagNames.defaultExperience = "defaultExperience";
export class MongoDBAccounts {
}
MongoDBAccounts.protocol = "https";
MongoDBAccounts.defaultPort = "10255";
export var MongoBackendEndpointType;
(function (MongoBackendEndpointType) {
    MongoBackendEndpointType[MongoBackendEndpointType["local"] = 0] = "local";
    MongoBackendEndpointType[MongoBackendEndpointType["remote"] = 1] = "remote";
})(MongoBackendEndpointType || (MongoBackendEndpointType = {}));
export class BackendApi {
}
BackendApi.GenerateToken = "GenerateToken";
BackendApi.PortalSettings = "PortalSettings";
BackendApi.AccountRestrictions = "AccountRestrictions";
BackendApi.RuntimeProxy = "RuntimeProxy";
BackendApi.DisallowedLocations = "DisallowedLocations";
BackendApi.SampleData = "SampleData";
export class PortalBackendEndpoints {
}
PortalBackendEndpoints.Development = "https://localhost:7235";
PortalBackendEndpoints.Mpac = "https://cdb-ms-mpac-pbe.cosmos.azure.com";
PortalBackendEndpoints.Prod = "https://cdb-ms-prod-pbe.cosmos.azure.com";
PortalBackendEndpoints.Fairfax = "https://cdb-ff-prod-pbe.cosmos.azure.us";
PortalBackendEndpoints.Mooncake = "https://cdb-mc-prod-pbe.cosmos.azure.cn";
export class MongoProxyEndpoints {
}
MongoProxyEndpoints.Local = "https://localhost:7238";
MongoProxyEndpoints.Mpac = "https://cdb-ms-mpac-mp.cosmos.azure.com";
MongoProxyEndpoints.Prod = "https://cdb-ms-prod-mp.cosmos.azure.com";
MongoProxyEndpoints.Fairfax = "https://cdb-ff-prod-mp.cosmos.azure.us";
MongoProxyEndpoints.Mooncake = "https://cdb-mc-prod-mp.cosmos.azure.cn";
export class MongoProxyApi {
}
MongoProxyApi.ResourceList = "ResourceList";
MongoProxyApi.QueryDocuments = "QueryDocuments";
MongoProxyApi.CreateDocument = "CreateDocument";
MongoProxyApi.ReadDocument = "ReadDocument";
MongoProxyApi.UpdateDocument = "UpdateDocument";
MongoProxyApi.DeleteDocument = "DeleteDocument";
MongoProxyApi.CreateCollectionWithProxy = "CreateCollectionWithProxy";
MongoProxyApi.LegacyMongoShell = "LegacyMongoShell";
MongoProxyApi.BulkDelete = "BulkDelete";
export class CassandraProxyEndpoints {
}
CassandraProxyEndpoints.Development = "https://localhost:7240";
CassandraProxyEndpoints.Mpac = "https://cdb-ms-mpac-cp.cosmos.azure.com";
CassandraProxyEndpoints.Prod = "https://cdb-ms-prod-cp.cosmos.azure.com";
CassandraProxyEndpoints.Fairfax = "https://cdb-ff-prod-cp.cosmos.azure.us";
CassandraProxyEndpoints.Mooncake = "https://cdb-mc-prod-cp.cosmos.azure.cn";
//TODO: Remove this when new backend is migrated over
export class CassandraBackend {
}
CassandraBackend.createOrDeleteApi = "api/cassandra/createordelete";
CassandraBackend.guestCreateOrDeleteApi = "api/guest/cassandra/createordelete";
CassandraBackend.queryApi = "api/cassandra";
CassandraBackend.guestQueryApi = "api/guest/cassandra";
CassandraBackend.keysApi = "api/cassandra/keys";
CassandraBackend.guestKeysApi = "api/guest/cassandra/keys";
CassandraBackend.schemaApi = "api/cassandra/schema";
CassandraBackend.guestSchemaApi = "api/guest/cassandra/schema";
export class CassandraProxyAPIs {
}
CassandraProxyAPIs.createOrDeleteApi = "api/cassandra/createordelete";
CassandraProxyAPIs.connectionStringCreateOrDeleteApi = "api/connectionstring/cassandra/createordelete";
CassandraProxyAPIs.queryApi = "api/cassandra";
CassandraProxyAPIs.connectionStringQueryApi = "api/connectionstring/cassandra";
CassandraProxyAPIs.keysApi = "api/cassandra/keys";
CassandraProxyAPIs.connectionStringKeysApi = "api/connectionstring/cassandra/keys";
CassandraProxyAPIs.schemaApi = "api/cassandra/schema";
CassandraProxyAPIs.connectionStringSchemaApi = "api/connectionstring/cassandra/schema";
export class AadEndpoints {
}
AadEndpoints.Prod = "https://login.microsoftonline.com/";
AadEndpoints.Fairfax = "https://login.microsoftonline.us/";
AadEndpoints.Mooncake = "https://login.partner.microsoftonline.cn/";
export class Queries {
}
Queries.CustomPageOption = "custom";
Queries.UnlimitedPageOption = "unlimited";
Queries.itemsPerPage = 100;
Queries.unlimitedItemsPerPage = 100; // TODO: Figure out appropriate value so it works for accounts with a large number of partitions
Queries.containersPerPage = 50;
Queries.QueryEditorMinHeightRatio = 0.1;
Queries.QueryEditorMaxHeightRatio = 0.4;
Queries.DefaultMaxDegreeOfParallelism = 6;
Queries.DefaultRetryAttempts = 9;
Queries.DefaultRetryIntervalInMs = 0;
Queries.DefaultMaxWaitTimeInSeconds = 30;
export class RBACOptions {
}
RBACOptions.setAutomaticRBACOption = "Automatic";
RBACOptions.setTrueRBACOption = "True";
RBACOptions.setFalseRBACOption = "False";
export class SavedQueries {
}
SavedQueries.CollectionName = "___Query";
SavedQueries.DatabaseName = "___Cosmos";
SavedQueries.OfferThroughput = 400;
SavedQueries.PartitionKeyProperty = "id";
export class DocumentsGridMetrics {
}
DocumentsGridMetrics.DocumentsPerPage = 100;
DocumentsGridMetrics.IndividualRowHeight = 34;
DocumentsGridMetrics.BufferHeight = 28;
DocumentsGridMetrics.SplitterMinWidth = 200;
DocumentsGridMetrics.SplitterMaxWidth = 360;
DocumentsGridMetrics.DocumentEditorMinWidthRatio = 0.2;
DocumentsGridMetrics.DocumentEditorMaxWidthRatio = 0.4;
export class Areas {
}
Areas.ResourceTree = "Resource Tree";
Areas.ContextualPane = "Contextual Pane";
Areas.Tab = "Tab";
Areas.ShareDialog = "Share Access Dialog";
Areas.Notebook = "Notebook";
Areas.Copilot = "Copilot";
export class HttpHeaders {
}
HttpHeaders.activityId = "x-ms-activity-id";
HttpHeaders.apiType = "x-ms-cosmos-apitype";
HttpHeaders.authorization = "authorization";
HttpHeaders.collectionIndexTransformationProgress = "x-ms-documentdb-collection-index-transformation-progress";
HttpHeaders.continuation = "x-ms-continuation";
HttpHeaders.correlationRequestId = "x-ms-correlation-request-id";
HttpHeaders.enableScriptLogging = "x-ms-documentdb-script-enable-logging";
HttpHeaders.guestAccessToken = "x-ms-encrypted-auth-token";
HttpHeaders.getReadOnlyKey = "x-ms-get-read-only-key";
HttpHeaders.connectionString = "x-ms-connection-string";
HttpHeaders.msDate = "x-ms-date";
HttpHeaders.location = "Location";
HttpHeaders.contentType = "Content-Type";
HttpHeaders.offerReplacePending = "x-ms-offer-replace-pending";
HttpHeaders.user = "x-ms-user";
HttpHeaders.populatePartitionStatistics = "x-ms-documentdb-populatepartitionstatistics";
HttpHeaders.queryMetrics = "x-ms-documentdb-query-metrics";
HttpHeaders.requestCharge = "x-ms-request-charge";
HttpHeaders.resourceQuota = "x-ms-resource-quota";
HttpHeaders.resourceUsage = "x-ms-resource-usage";
HttpHeaders.retryAfterMs = "x-ms-retry-after-ms";
HttpHeaders.scriptLogResults = "x-ms-documentdb-script-log-results";
HttpHeaders.populateCollectionThroughputInfo = "x-ms-documentdb-populatecollectionthroughputinfo";
HttpHeaders.supportSpatialLegacyCoordinates = "x-ms-documentdb-supportspatiallegacycoordinates";
HttpHeaders.usePolygonsSmallerThanAHemisphere = "x-ms-documentdb-usepolygonssmallerthanahemisphere";
HttpHeaders.autoPilotThroughput = "autoscaleSettings";
HttpHeaders.autoPilotThroughputSDK = "x-ms-cosmos-offer-autopilot-settings";
HttpHeaders.partitionKey = "x-ms-documentdb-partitionkey";
HttpHeaders.migrateOfferToManualThroughput = "x-ms-cosmos-migrate-offer-to-manual-throughput";
HttpHeaders.migrateOfferToAutopilot = "x-ms-cosmos-migrate-offer-to-autopilot";
HttpHeaders.xAPIKey = "X-API-Key";
export class ContentType {
}
ContentType.applicationJson = "application/json";
export class ApiType {
}
// Mapped to hexadecimal values in the backend
ApiType.MongoDB = 1;
ApiType.Gremlin = 2;
ApiType.Cassandra = 4;
ApiType.Table = 8;
ApiType.SQL = 16;
export class HttpStatusCodes {
}
HttpStatusCodes.OK = 200;
HttpStatusCodes.Created = 201;
HttpStatusCodes.Accepted = 202;
HttpStatusCodes.NoContent = 204;
HttpStatusCodes.NotModified = 304;
HttpStatusCodes.BadRequest = 400;
HttpStatusCodes.Unauthorized = 401;
HttpStatusCodes.Forbidden = 403;
HttpStatusCodes.NotFound = 404;
HttpStatusCodes.TooManyRequests = 429;
HttpStatusCodes.Conflict = 409;
HttpStatusCodes.InternalServerError = 500;
HttpStatusCodes.BadGateway = 502;
HttpStatusCodes.ServiceUnavailable = 503;
HttpStatusCodes.GatewayTimeout = 504;
HttpStatusCodes.RetryableStatusCodes = [
    HttpStatusCodes.TooManyRequests,
    HttpStatusCodes.InternalServerError,
    HttpStatusCodes.BadGateway,
    HttpStatusCodes.ServiceUnavailable,
    HttpStatusCodes.GatewayTimeout,
];
export class Urls {
}
Urls.feedbackEmail = "https://aka.ms/cosmosdbfeedback?subject=Cosmos%20DB%20Data%20Explorer%20Feedback";
Urls.autoscaleMigration = "https://aka.ms/cosmos-autoscale-migration";
Urls.freeTierInformation = "https://aka.ms/cosmos-free-tier";
Urls.cosmosPricing = "https://aka.ms/azure-cosmos-db-pricing";
export class HashRoutePrefixes {
    static databasesWithId(databaseId) {
        return this.databases.replace("{db_id}", databaseId).replace("/", ""); // strip the first slash since hasher adds it
    }
    static collectionsWithIds(databaseId, collectionId) {
        const transformedDatabasePrefix = this.collections.replace("{db_id}", databaseId);
        return transformedDatabasePrefix.replace("{coll_id}", collectionId).replace("/", ""); // strip the first slash since hasher adds it
    }
    static sprocWithIds(databaseId, collectionId, sprocId, stripFirstSlash = true) {
        const transformedDatabasePrefix = this.sprocs.replace("{db_id}", databaseId);
        const transformedSprocRoute = transformedDatabasePrefix
            .replace("{coll_id}", collectionId)
            .replace("{sproc_id}", sprocId);
        if (!!stripFirstSlash) {
            return transformedSprocRoute.replace("/", ""); // strip the first slash since hasher adds it
        }
        return transformedSprocRoute;
    }
    static conflictsWithIds(databaseId, collectionId) {
        const transformedDatabasePrefix = this.conflicts.replace("{db_id}", databaseId);
        return transformedDatabasePrefix.replace("{coll_id}", collectionId).replace("/", ""); // strip the first slash since hasher adds it;
    }
    static docsWithIds(databaseId, collectionId, docId) {
        const transformedDatabasePrefix = this.docs.replace("{db_id}", databaseId);
        return transformedDatabasePrefix.replace("{coll_id}", collectionId).replace("{doc_id}", docId).replace("/", ""); // strip the first slash since hasher adds it
    }
}
HashRoutePrefixes.databases = "/dbs/{db_id}";
HashRoutePrefixes.collections = "/dbs/{db_id}/colls/{coll_id}";
HashRoutePrefixes.sprocHash = "/sprocs/";
HashRoutePrefixes.sprocs = HashRoutePrefixes.collections + HashRoutePrefixes.sprocHash + "{sproc_id}";
HashRoutePrefixes.docs = HashRoutePrefixes.collections + "/docs/{doc_id}/";
HashRoutePrefixes.conflicts = HashRoutePrefixes.collections + "/conflicts";
export class ConfigurationOverridesValues {
}
ConfigurationOverridesValues.IsBsonSchemaV2 = "true";
export class KeyCodes {
}
KeyCodes.Space = 32;
KeyCodes.Enter = 13;
KeyCodes.Escape = 27;
KeyCodes.UpArrow = 38;
KeyCodes.DownArrow = 40;
KeyCodes.LeftArrow = 37;
KeyCodes.RightArrow = 39;
KeyCodes.Tab = 9;
// Normalized per: https://www.w3.org/TR/uievents-key/#named-key-attribute-values
export class NormalizedEventKey {
}
NormalizedEventKey.Space = " ";
NormalizedEventKey.Enter = "Enter";
NormalizedEventKey.Escape = "Escape";
NormalizedEventKey.UpArrow = "ArrowUp";
NormalizedEventKey.DownArrow = "ArrowDown";
NormalizedEventKey.LeftArrow = "ArrowLeft";
NormalizedEventKey.RightArrow = "ArrowRight";
export class TryCosmosExperience {
}
TryCosmosExperience.extendUrl = "https://trycosmosdb.azure.com/api/resource/extendportal?userId={0}";
TryCosmosExperience.deleteUrl = "https://trycosmosdb.azure.com/api/resource/deleteportal?userId={0}";
TryCosmosExperience.collectionsPerAccount = 3;
TryCosmosExperience.maxRU = 5000;
TryCosmosExperience.defaultRU = 3000;
export class OfferVersions {
}
OfferVersions.V1 = "V1";
OfferVersions.V2 = "V2";
export var ConflictOperationType;
(function (ConflictOperationType) {
    ConflictOperationType["Replace"] = "replace";
    ConflictOperationType["Create"] = "create";
    ConflictOperationType["Delete"] = "delete";
})(ConflictOperationType || (ConflictOperationType = {}));
export var ConnectionStatusType;
(function (ConnectionStatusType) {
    ConnectionStatusType["Connect"] = "Connect";
    ConnectionStatusType["Connecting"] = "Connecting";
    ConnectionStatusType["Connected"] = "Connected";
    ConnectionStatusType["Failed"] = "Connection Failed";
    ConnectionStatusType["Reconnect"] = "Reconnect";
})(ConnectionStatusType || (ConnectionStatusType = {}));
export var ContainerStatusType;
(function (ContainerStatusType) {
    ContainerStatusType["Active"] = "Active";
    ContainerStatusType["Disconnected"] = "Disconnected";
})(ContainerStatusType || (ContainerStatusType = {}));
export var PoolIdType;
(function (PoolIdType) {
    PoolIdType["DefaultPoolId"] = "default";
    PoolIdType["QueryCopilot"] = "query-copilot";
})(PoolIdType || (PoolIdType = {}));
export const EmulatorMasterKey = 
//[SuppressMessage("Microsoft.Security", "CS002:SecretInNextLine", Justification="Well known public masterKey for emulator")]
"C2y6yDjf5/R+ob0N8A7Cgv30VRDJIWEHLM+4QDU5DE2nQ9nDuVTqobD4b8mGGyPMbIZnqyMsEcaGQy67XIw/Jw==";
export class Notebook {
}
Notebook.defaultBasePath = "./notebooks";
Notebook.heartbeatDelayMs = 60000;
Notebook.containerStatusHeartbeatDelayMs = 30000;
Notebook.kernelRestartInitialDelayMs = 1000;
Notebook.kernelRestartMaxDelayMs = 20000;
Notebook.autoSaveIntervalMs = 300000;
Notebook.memoryGuageToGB = 1048576;
Notebook.lowMemoryThreshold = 0.8;
Notebook.remainingTimeForAlert = 10;
Notebook.retryAttempts = 3;
Notebook.retryAttemptDelayMs = 5000;
Notebook.temporarilyDownMsg = "Notebooks is currently not available. We are working on it.";
Notebook.mongoShellTemporarilyDownMsg = "We have identified an issue with the Mongo Shell and it is unavailable right now. We are actively working on the mitigation.";
Notebook.cassandraShellTemporarilyDownMsg = "We have identified an issue with the Cassandra Shell and it is unavailable right now. We are actively working on the mitigation.";
Notebook.saveNotebookModalTitle = "Save notebook in temporary workspace";
Notebook.saveNotebookModalContent = "This notebook will be saved in the temporary workspace and will be removed when the session expires.";
Notebook.newNotebookModalTitle = "Create notebook in temporary workspace";
Notebook.newNotebookUploadModalTitle = "Upload notebook to temporary workspace";
Notebook.newNotebookModalContent1 = "A temporary workspace will be created to enable you to work with notebooks. When the session expires, any notebooks in the workspace will be removed.";
Notebook.newNotebookModalContent2 = "To save your work permanently, save your notebooks to a GitHub repository or download the notebooks to your local machine before the session ends. ";
Notebook.galleryNotebookDownloadContent1 = "To download, run, and make changes to this sample notebook, a temporary workspace will be created. When the session expires, any notebooks in the workspace will be removed.";
Notebook.galleryNotebookDownloadContent2 = "To save your work permanently, save your notebooks to a GitHub repository or download the Notebooks to your local machine before the session ends. ";
Notebook.cosmosNotebookHomePageUrl = "https://aka.ms/cosmos-notebooks-limits";
Notebook.cosmosNotebookGitDocumentationUrl = "https://aka.ms/cosmos-notebooks-github";
Notebook.learnMore = "Learn more.";
export class SparkLibrary {
}
SparkLibrary.nameMinLength = 3;
SparkLibrary.nameMaxLength = 63;
export class AnalyticalStorageTtl {
}
AnalyticalStorageTtl.Days90 = 7776000;
AnalyticalStorageTtl.Infinite = -1;
AnalyticalStorageTtl.Disabled = 0;
export class TerminalQueryParams {
}
TerminalQueryParams.Terminal = "terminal";
TerminalQueryParams.Server = "server";
TerminalQueryParams.Token = "token";
TerminalQueryParams.SubscriptionId = "subscriptionId";
TerminalQueryParams.TerminalEndpoint = "terminalEndpoint";
export class JunoEndpoints {
}
JunoEndpoints.Test = "https://juno-test.documents-dev.windows-int.net";
JunoEndpoints.Test2 = "https://juno-test2.documents-dev.windows-int.net";
JunoEndpoints.Test3 = "https://juno-test3.documents-dev.windows-int.net";
JunoEndpoints.Prod = "https://tools.cosmos.azure.com";
JunoEndpoints.Stage = "https://tools-staging.cosmos.azure.com";
export class PriorityLevel {
}
PriorityLevel.High = "high";
PriorityLevel.Low = "low";
PriorityLevel.Default = "low";
export const QueryCopilotSampleDatabaseId = "CopilotSampleDB";
export const QueryCopilotSampleContainerId = "SampleContainer";
export const QueryCopilotSampleContainerSchema = {
    product: {
        sampleData: {
            id: "c415e70f-9bf5-4cda-aebe-a290cb8b94c2",
            name: "Amazing Phone 3000 (Black)",
            price: 223.33,
            category: "Electronics",
            description: "This Amazing Phone 3000 (Black) is made of black metal! It has a very well made aluminum body and it feels very comfortable. We loved the sound that comes out of it! Also, the design of the phone was a little loose at first because I was using the camera and felt uncomfortable wearing it. The phone is actually made slightly smaller than these photos! This is due to the addition of a 3.3mm filter",
            stock: 84,
            countryOfOrigin: "USA",
            firstAvailable: "2018-09-07 19:41:44",
            priceHistory: [238.68, 234.7, 221.49, 205.88, 220.15],
            customerRatings: [
                {
                    username: "steven66",
                    firstName: "Carol",
                    gender: "female",
                    lastName: "Shelton",
                    age: "25-35",
                    area: "suburban",
                    address: "261 Collins Burgs Apt. 332\nNorth Taylor, NM 32268",
                    stars: 5,
                    date: "2021-04-22 13:42:14",
                    verifiedUser: true,
                },
                {
                    username: "khudson",
                    firstName: "Ronald",
                    gender: "male",
                    lastName: "Webb",
                    age: "18-24",
                    area: "suburban",
                    address: "9912 Parker Court Apt. 068\nNorth Austin, HI 76225",
                    stars: 5,
                    date: "2021-02-07 07:00:22",
                    verifiedUser: false,
                },
                {
                    username: "lfrancis",
                    firstName: "Brady",
                    gender: "male",
                    lastName: "Wright",
                    age: "35-45",
                    area: "urban",
                    address: "PSC 5437, Box 3159\nAPO AA 26385",
                    stars: 2,
                    date: "2022-02-23 21:40:10",
                    verifiedUser: false,
                },
                {
                    username: "nicolemartinez",
                    firstName: "Megan",
                    gender: "female",
                    lastName: "Tran",
                    age: "18-24",
                    area: "rural",
                    address: "7445 Salazar Brooks\nNew Sarah, PW 18097",
                    stars: 4,
                    date: "2021-09-01 22:21:40",
                    verifiedUser: false,
                },
                {
                    username: "uguzman",
                    firstName: "Deanna",
                    gender: "female",
                    lastName: "Campbell",
                    age: "18-24",
                    area: "urban",
                    address: "41104 Moreno Fort Suite 872\nPort Michaelbury, AK 48712",
                    stars: 1,
                    date: "2022-03-07 02:23:14",
                    verifiedUser: false,
                },
                {
                    username: "rebeccahunt",
                    firstName: "Jared",
                    gender: "male",
                    lastName: "Lopez",
                    age: "18-24",
                    area: "rural",
                    address: "392 Morgan Village Apt. 785\nGreenshire, CT 05921",
                    stars: 5,
                    date: "2021-04-17 04:17:49",
                    verifiedUser: false,
                },
            ],
            rareProperty: true,
        },
        schema: {
            properties: {
                id: {
                    type: "string",
                },
                name: {
                    type: "string",
                },
                price: {
                    type: "number",
                },
                category: {
                    type: "string",
                },
                description: {
                    type: "string",
                },
                stock: {
                    type: "number",
                },
                countryOfOrigin: {
                    type: "string",
                },
                firstAvailable: {
                    type: "string",
                },
                priceHistory: {
                    items: {
                        type: "number",
                    },
                    type: "array",
                },
                customerRatings: {
                    items: {
                        properties: {
                            username: {
                                type: "string",
                            },
                            firstName: {
                                type: "string",
                            },
                            gender: {
                                type: "string",
                            },
                            lastName: {
                                type: "string",
                            },
                            age: {
                                type: "string",
                            },
                            area: {
                                type: "string",
                            },
                            address: {
                                type: "string",
                            },
                            stars: {
                                type: "number",
                            },
                            date: {
                                type: "string",
                            },
                            verifiedUser: {
                                type: "boolean",
                            },
                        },
                        type: "object",
                    },
                    type: "array",
                },
                rareProperty: {
                    type: "boolean",
                },
            },
            type: "object",
        },
    },
};
export const ShortenedQueryCopilotSampleContainerSchema = {
    containerSchema: {
        product: {
            sampleData: {
                categoryName: "Components, Saddles",
                name: "LL Road Seat/Saddle",
                price: 27.12,
                tags: [
                    {
                        id: "0573D684-9140-4DEE-89AF-4E4A90E65666",
                        name: "Tag-113",
                    },
                    {
                        id: "6C2F05C8-1E61-4912-BE1A-C67A378429BB",
                        name: "Tag-5",
                    },
                ],
            },
            schema: {
                properties: {
                    categoryName: {
                        type: "string",
                    },
                    name: {
                        type: "string",
                    },
                    price: {
                        type: "number",
                    },
                    tags: {
                        items: {
                            properties: {
                                id: {
                                    type: "string",
                                },
                                name: {
                                    type: "string",
                                },
                            },
                            type: "object",
                        },
                        type: "array",
                    },
                },
                type: "object",
            },
        },
    },
    userPrompt: "find all products",
};
