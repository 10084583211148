module.exports = {
  "DataExplorerFont": "wf_segoe-ui_normal, \"Segoe UI\", \"Segoe WP\", Tahoma, Arial, sans-serif",
  "SemiboldFont": "\"Segoe UI Semibold\", \"Segoe UI\", \"Segoe WP\", Tahoma, Arial, sans-serif",
  "GrayScale": "grayscale()",
  "NoColor": "brightness(0) saturate(100%)",
  "xSmallFontSize": "4px",
  "smallFontSize": "8px",
  "mediumFontSize": "12px",
  "DefaultFontSize": "14px",
  "largeFontSize": "16px",
  "AccentMediumHigh": "#0058ad",
  "AccentMedium": "#004e87",
  "AccentHigh": "#1ebaed",
  "AccentExtraHigh": "#55b3ff",
  "AccentLow": "#edf6ff",
  "AccentMediumLow": "#ddeefe",
  "AccentLight": "#eef7ff",
  "AccentExtra": "#ddf0ff",
  "SelectionHigh": "#b91f26",
  "BaseLight": "#ffffff",
  "BaseDark": "#000000",
  "NotificationLow": "#fff4ce",
  "NotificationHigh": "#f9e9b0",
  "Purple1": "#8a2da5",
  "Dirty": "#9b4f96",
  "BaseLow": "#f2f2f2",
  "BaseMediumLow": "#e6e6e6",
  "BaseMedium": "#cccccc",
  "BaseMediumHigh": "#767676",
  "BaseHigh": "#393939",
  "BoxShadow": "rgba(118, 118, 118, 0.65)",
  "GreyOutOpacity": "0.65",
  "DefaultFontColor": "@BaseHigh",
  "DividerColor": "@BaseMedium",
  "InfoPointerColor": "#3b3b3b",
  "ErrorColor": "@SelectionHigh",
  "SelectionColor": "#3074b0",
  "FocusColor": "#605e5c",
  "GalleryBackgroundColor": "#fdfdfd",
  "InfoIconColor": "#0072c6",
  "WarningIconColor": "#db7500",
  "ErrorIconColor": "#b91f26",
  "SmallSpace": "4px",
  "DefaultSpace": "8px",
  "MediumSpace": "12px",
  "LargeSpace": "16px",
  "ButtonBorderWidth": "1px",
  "RightPaneWidth": "440px",
  "RightPaneMinHeight": "565px",
  "PaneLoaderWidth": "110px",
  "PaneLoaderHeight": "10px",
  "ButtonIconHeight": "18px",
  "ScaleFormWidth": "280px",
  "ImgWidth": "14px",
  "ImgHeight": "14px",
  "toggleFontWeight": "700",
  "TreeLineHeight": "17px",
  "topcommandbarheight": "36px",
  "LoaderHeight": "6px",
  "LoaderWidth": "24px",
  "HdeAccountSplitterHeight": "20px",
  "AccountNavigationImgWidth": "350px",
  "AccountNavigationImgHeight": "190px",
  "AccountNavigationExpandCollapseSize": "12px",
  "IETableDataWidth": "650px",
  "newCollectionPaneInputWidth": "300px",
  "tooltipTextWidth": "280px",
  "sharedCollectionThroughputTooltipTextWidth": "150px",
  "mongoWildcardIndexTooltipWidth": "150px",
  "addContainerPaneThroughputInfoWidth": "370px",
  "optionsInfoWidth": "210px",
  "noFixedCollectionsTooltipWidth": "196px",
  "ThroughputInfoWidth": "215px",
  "ThroughputRuInfoWidth": "175px",
  "RenewAccessInfoWidth": "115px",
  "ErrorDetailsInfowidth": "145px",
  "AccessKeyInputWidth": "370px",
  "provisionDatabaseThroughputInfo": "200px",
  "ActiveTabHeight": "31px",
  "ActiveTabWidth": "141px",
  "TabsHeight": "30px",
  "TabsWidth": "140px",
  "ContentWrapper": "111px",
  "StatusIconContainerSize": "18px",
  "LoadingErrorIconSize": "14px",
  "ErrorIconContainer": "16px",
  "ErrorIconWidth": "3px",
  "FormErrorWidth": "320px",
  "WarningErrorIconSize": "32px",
  "Loader_z-index": "5",
  "NotificationConsole_z-index": "2",
  "screen": "screen\\0",
  "CommandBarButtonHeight": "40px",
  "FabricCommandBarButtonHeight": "34px",
  "PortalAccentMediumHigh": "#0058ad",
  "PortalAccentMedium": "#004e87",
  "PortalAccentLight": "#eef7ff",
  "PortalAccentAccentExtra": "#ddf0ff",
  "FabricFont": "\"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif",
  "FabricToolbarIconColor": "brightness(0) saturate(100%) invert(50%) sepia(17%) saturate(1459%) hue-rotate(81deg) brightness(99%) contrast(94%)",
  "FabricBoxBorderRadius": "8px",
  "FabricBoxBorderShadow": "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
  "FabricBoxMargin": "4px 8px 4px 8px",
  "FabricAccentMediumHigh": "#0c695a",
  "FabricAccentMedium": "#117865",
  "FabricAccentLight": "#f5f5f5",
  "FabricAccentExtra": "#ebebeb",
  "FabricButtonBorderRadius": "4px",
  "display": "flex) {\n  display:~\"-webkit-@{display}\"",
  "direction": "column) {\n  -webkit-flex-direction:@direction",
  "media all and (-ms-high-contrast": "none),\n(-ms-high-contrast:active) {\n\n  .selectedRadio,\n  .selectedRadio:hover,\n  .selectedRadio:active,\n  .selectedRadio.dirty,\n  .tab [type=\"radio\"]:checked~label,\n  .tab [type=\"radio\"]:checked~label:hover {\n    -ms-high-contrast-adjust:none",
  "ToggleHeight": "30px",
  "ToggleWidth": "180px",
  "textColor": "@BaseLight, @backgroundColor:@BaseHigh) {\n  background-color:@backgroundColor",
  "color": "@BaseDark) {\n  content:\"\""
};
