import { Dropdown } from "@fluentui/react";
import * as React from "react";
export const SwitchAccount = ({ accounts, setSelectedAccountName, selectedAccount, dismissMenu, }) => {
    return (React.createElement(Dropdown, { label: "Cosmos DB Account Name", className: "accountSwitchAccountDropdown", options: accounts === null || accounts === void 0 ? void 0 : accounts.map((account) => ({
            key: account.name,
            text: account.name,
            data: account,
        })), onChange: (_, option) => {
            setSelectedAccountName(String(option === null || option === void 0 ? void 0 : option.key));
            dismissMenu();
        }, defaultSelectedKey: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.name, placeholder: accounts && accounts.length === 0 ? "No Accounts Found" : "Select an Account", styles: {
            callout: "accountSwitchAccountDropdownMenu",
        } }));
};
