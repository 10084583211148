import { SubscriptionType } from "../Contracts/SubscriptionType";
export const hoursInAMonth = 730;
export class AutoscalePricing {
}
AutoscalePricing.MonthlyPricing = {
    default: {
        singleMaster: {
            Currency: "USD",
            CurrencySign: "$",
            Standard: {
                StartingPrice: 24,
                PricePerRU: 0.09,
                PricePerGB: 0.25,
            },
        },
        multiMaster: {
            Currency: "USD",
            CurrencySign: "$",
            Standard: {
                StartingPrice: 24,
                PricePerRU: 0.12,
                PricePerGB: 0.25,
            },
        },
    },
    mooncake: {
        singleMaster: {
            Currency: "RMB",
            CurrencySign: "¥",
            Standard: {
                StartingPrice: 152,
                PricePerRU: 0.57,
                PricePerGB: 2.576,
            },
        },
        multiMaster: {
            Currency: "RMB",
            CurrencySign: "¥",
            Standard: {
                StartingPrice: 152,
                PricePerRU: 0.76,
                PricePerGB: 2.576,
            },
        },
    },
};
AutoscalePricing.HourlyPricing = {
    default: {
        singleMaster: {
            Currency: "USD",
            CurrencySign: "$",
            Standard: {
                StartingPrice: 24 / hoursInAMonth,
                PricePerRU: 0.00012,
                PricePerGB: 0.25 / hoursInAMonth,
            },
        },
        multiMaster: {
            Currency: "USD",
            CurrencySign: "$",
            Standard: {
                StartingPrice: 24 / hoursInAMonth,
                PricePerRU: 0.00016,
                PricePerGB: 0.25 / hoursInAMonth,
            },
        },
    },
    mooncake: {
        singleMaster: {
            Currency: "RMB",
            CurrencySign: "¥",
            Standard: {
                StartingPrice: AutoscalePricing.MonthlyPricing.mooncake.singleMaster.Standard.StartingPrice / hoursInAMonth,
                PricePerRU: 0.000765,
                PricePerGB: AutoscalePricing.MonthlyPricing.mooncake.singleMaster.Standard.PricePerGB / hoursInAMonth,
            },
        },
        multiMaster: {
            Currency: "RMB",
            CurrencySign: "¥",
            Standard: {
                StartingPrice: AutoscalePricing.MonthlyPricing.mooncake.multiMaster.Standard.StartingPrice / hoursInAMonth,
                PricePerRU: 0.00102,
                PricePerGB: AutoscalePricing.MonthlyPricing.mooncake.multiMaster.Standard.PricePerGB / hoursInAMonth,
            },
        },
    },
};
export class OfferPricing {
}
OfferPricing.MonthlyPricing = {
    default: {
        Currency: "USD",
        CurrencySign: "$",
        S1Price: 25,
        S2Price: 50,
        S3Price: 100,
        Standard: {
            StartingPrice: 24,
            PricePerRU: 0.06,
            PricePerGB: 0.25,
        },
    },
    mooncake: {
        Currency: "RMB",
        CurrencySign: "¥",
        S1Price: 110.3,
        S2Price: 220.6,
        S3Price: 441.2,
        Standard: {
            StartingPrice: 152,
            PricePerRU: 0.3794,
            PricePerGB: 2.576,
        },
    },
};
OfferPricing.HourlyPricing = {
    default: {
        Currency: "USD",
        CurrencySign: "$",
        S1Price: 0.0336,
        S2Price: 0.0672,
        S3Price: 0.1344,
        Standard: {
            StartingPrice: 24 / hoursInAMonth,
            SingleMasterPricePerRU: 0.00008,
            MultiMasterPricePerRU: 0.00016,
            PricePerGB: 0.25 / hoursInAMonth,
        },
    },
    mooncake: {
        Currency: "RMB",
        CurrencySign: "¥",
        S1Price: 0.15,
        S2Price: 0.3,
        S3Price: 0.6,
        Standard: {
            StartingPrice: OfferPricing.MonthlyPricing.mooncake.Standard.StartingPrice / hoursInAMonth,
            SingleMasterPricePerRU: 0.00051,
            MultiMasterPricePerRU: 0.00102,
            PricePerGB: OfferPricing.MonthlyPricing.mooncake.Standard.PricePerGB / hoursInAMonth,
        },
    },
};
export class CollectionCreation {
}
// TODO generate these values based on Product\Services\Documents\ImageStore\GatewayApplication\Settings.xml
CollectionCreation.MinRUPerPartitionBelow7Partitions = 400;
CollectionCreation.MinRU7PartitionsTo25Partitions = 2500;
CollectionCreation.MinRUPerPartitionAbove25Partitions = 100;
CollectionCreation.MaxRUPerPartition = 10000;
CollectionCreation.MinPartitionedCollectionRUs = 2500;
CollectionCreation.NumberOfPartitionsInFixedCollection = 1;
CollectionCreation.NumberOfPartitionsInUnlimitedCollection = 10;
CollectionCreation.storage10Gb = "10";
CollectionCreation.storage100Gb = "100";
CollectionCreation.DefaultCollectionRUs1000 = 1000;
CollectionCreation.DefaultCollectionRUs10K = 10000;
CollectionCreation.DefaultCollectionRUs400 = 400;
CollectionCreation.DefaultCollectionRUs2000 = 2000;
CollectionCreation.DefaultCollectionRUs2500 = 2500;
CollectionCreation.DefaultCollectionRUs5000 = 5000;
CollectionCreation.DefaultCollectionRUs15000 = 15000;
CollectionCreation.DefaultCollectionRUs20000 = 20000;
CollectionCreation.DefaultCollectionRUs25000 = 25000;
CollectionCreation.DefaultCollectionRUs100K = 100000;
CollectionCreation.DefaultCollectionRUs1Million = 1000000;
CollectionCreation.DefaultSubscriptionType = SubscriptionType.Free;
CollectionCreation.TablesAPIDefaultDatabase = "TablesDB";
export const CollectionCreationDefaults = {
    storage: CollectionCreation.storage100Gb,
    throughput: {
        fixed: CollectionCreation.DefaultCollectionRUs400,
        unlimited: CollectionCreation.DefaultCollectionRUs400,
        unlimitedmax: CollectionCreation.DefaultCollectionRUs1Million,
        unlimitedmin: CollectionCreation.DefaultCollectionRUs400,
        shared: CollectionCreation.DefaultCollectionRUs400,
    },
};
export class SubscriptionUtilMappings {
}
SubscriptionUtilMappings.FreeTierSubscriptionIds = [
    "b8f2ff04-0a81-4cf9-95ef-5828d16981d2",
    "39b1fdff-e5b2-4f83-adb4-33cb3aabf5ea",
    "41f6d14d-ece1-46e4-942c-02c00d67f7d6",
    "11dc62e3-77dc-4ef5-a46b-480ec6caa8fe",
    "199d0919-60bd-448e-b64d-8461a0fe9747",
    "a57b6849-d443-44cf-a3b7-7dd07ead9401",
];
export class AutopilotDocumentation {
}
AutopilotDocumentation.Url = "https://aka.ms/cosmos-autoscale-info";
export class FreeTierLimits {
}
FreeTierLimits.RU = 1000;
FreeTierLimits.Storage = 25;
export class QueryConstants {
}
QueryConstants.CancelQueryTitle = "Cancel query";
QueryConstants.CancelQuerySubTextTemplate = "{0} Do you want to cancel this query?";
QueryConstants.CancelQueryTimeoutThresholdReached = "The query timeout threshold has been reached.";
