import { sendMessage } from "../../Common/MessageHandler";
import { configContext } from "../../ConfigContext";
import { MessageTypes } from "../../Contracts/ExplorerContracts";
import { userContext } from "../../UserContext";
import { startTrackEvent, stopTrackEvent, trackEvent } from "../appInsights";
import { Action, ActionModifiers } from "./TelemetryConstants";
export function trace(action, actionModifier = ActionModifiers.Mark, data = {}, type = MessageTypes.TelemetryInfo) {
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: actionModifier,
            data: JSON.stringify(decorateData(data)),
        },
    });
    trackEvent({ name: Action[action] }, decorateData(data, actionModifier));
}
export function traceStart(action, data, type = MessageTypes.TelemetryInfo) {
    const timestamp = Date.now();
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Start,
            timestamp: timestamp,
            data: JSON.stringify(decorateData(data)),
        },
    });
    startTrackEvent(Action[action]);
    return timestamp;
}
export function traceSuccess(action, data, timestamp, type = MessageTypes.TelemetryInfo) {
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Success,
            timestamp: timestamp || Date.now(),
            data: JSON.stringify(decorateData(data)),
        },
    });
    stopTrackEvent(Action[action], decorateData(data, ActionModifiers.Success));
}
export function traceFailure(action, data, timestamp, type = MessageTypes.TelemetryInfo) {
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Failed,
            timestamp: timestamp || Date.now(),
            data: JSON.stringify(decorateData(data)),
        },
    });
    stopTrackEvent(Action[action], decorateData(data, ActionModifiers.Failed));
}
export function traceCancel(action, data, timestamp, type = MessageTypes.TelemetryInfo) {
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Cancel,
            timestamp: timestamp || Date.now(),
            data: JSON.stringify(decorateData(data)),
        },
    });
    stopTrackEvent(Action[action], decorateData(data, ActionModifiers.Cancel));
}
export function traceOpen(action, data, timestamp, type = MessageTypes.TelemetryInfo) {
    const validTimestamp = timestamp || Date.now();
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Open,
            timestamp: validTimestamp,
            data: JSON.stringify(decorateData(data)),
        },
    });
    startTrackEvent(Action[action]);
    return validTimestamp;
}
export function traceMark(action, data, timestamp, type = MessageTypes.TelemetryInfo) {
    const validTimestamp = timestamp || Date.now();
    sendMessage({
        type: type,
        data: {
            action: Action[action],
            actionModifier: ActionModifiers.Mark,
            timestamp: validTimestamp,
            data: JSON.stringify(decorateData(data)),
        },
    });
    startTrackEvent(Action[action]);
    return validTimestamp;
}
function decorateData(data = {}, actionModifier) {
    var _a;
    return Object.assign({ databaseAccountName: (_a = userContext.databaseAccount) === null || _a === void 0 ? void 0 : _a.name, defaultExperience: userContext.apiType, authType: userContext.authType, subscriptionId: userContext.subscriptionId, platform: configContext.platform, env: process.env.NODE_ENV, actionModifier }, data);
}
