// TODO: Renable this rule for the file or turn it off everywhere
/* eslint-disable react/display-name */
import { DefaultButton } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { StyleConstants } from "../../../Common/StyleConstants";
import { useDatabaseAccounts } from "../../../hooks/useDatabaseAccounts";
import { useSubscriptions } from "../../../hooks/useSubscriptions";
import { SwitchAccount } from "./SwitchAccount";
import { SwitchSubscription } from "./SwitchSubscription";
const buttonStyles = {
    root: {
        fontSize: StyleConstants.DefaultFontSize,
        height: 40,
        padding: 0,
        paddingLeft: 10,
        marginRight: 5,
        backgroundColor: StyleConstants.BaseDark,
        color: StyleConstants.BaseLight,
    },
    rootHovered: {
        backgroundColor: StyleConstants.BaseHigh,
        color: StyleConstants.BaseLight,
    },
    rootFocused: {
        backgroundColor: StyleConstants.BaseHigh,
        color: StyleConstants.BaseLight,
    },
    rootPressed: {
        backgroundColor: StyleConstants.BaseHigh,
        color: StyleConstants.BaseLight,
    },
    rootExpanded: {
        backgroundColor: StyleConstants.BaseHigh,
        color: StyleConstants.BaseLight,
    },
    textContainer: {
        flexGrow: "initial",
    },
};
export const AccountSwitcher = ({ armToken, setDatabaseAccount }) => {
    const subscriptions = useSubscriptions(armToken);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(() => localStorage.getItem("cachedSubscriptionId"));
    const selectedSubscription = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.find((sub) => sub.subscriptionId === selectedSubscriptionId);
    const accounts = useDatabaseAccounts(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.subscriptionId, armToken);
    const [selectedAccountName, setSelectedAccountName] = useState(() => localStorage.getItem("cachedDatabaseAccountName"));
    const selectedAccount = accounts === null || accounts === void 0 ? void 0 : accounts.find((account) => account.name === selectedAccountName);
    useEffect(() => {
        if (selectedAccountName) {
            localStorage.setItem("cachedDatabaseAccountName", selectedAccountName);
        }
    }, [selectedAccountName]);
    useEffect(() => {
        if (selectedSubscriptionId) {
            localStorage.setItem("cachedSubscriptionId", selectedSubscriptionId);
        }
    }, [selectedSubscriptionId]);
    useEffect(() => {
        if (selectedAccount) {
            setDatabaseAccount(selectedAccount);
        }
    }, [selectedAccount]);
    const buttonText = (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.name) || "Select a Database Account";
    const items = [
        {
            key: "switchSubscription",
            onRender: () => React.createElement(SwitchSubscription, Object.assign({}, { subscriptions, setSelectedSubscriptionId, selectedSubscription })),
        },
        {
            key: "switchAccount",
            onRender: (_, dismissMenu) => (React.createElement(SwitchAccount, Object.assign({}, { accounts, dismissMenu, selectedAccount, setSelectedAccountName }))),
        },
    ];
    return (React.createElement(DefaultButton, { text: buttonText, menuProps: {
            directionalHintFixed: true,
            className: "accountSwitchContextualMenu",
            items,
        }, styles: buttonStyles, className: "accountSwitchButton", id: "accountSwitchButton" }));
};
