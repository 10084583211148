import * as React from "react";
import ConnectImage from "../../../../images/HdeConnectCosmosDB.svg";
import "../AadAuthorizationFailure.less";
export const AadAuthorizationFailure = ({ authFailure }) => {
    return (React.createElement("div", { id: "aadAuthFailure", className: "aadAuthFailureContainer", style: { display: "flex" } },
        React.createElement("div", { className: "aadAuthFailureFormContainer" },
            React.createElement("div", { className: "aadAuthFailure" },
                React.createElement("p", { className: "aadAuthFailureContent" },
                    React.createElement("img", { src: ConnectImage, alt: "Azure Cosmos DB" })),
                React.createElement("p", { className: "authFailureTitle" }, "Authorization Failure"),
                React.createElement("p", { className: "authFailureMessage" }, authFailure.failureMessage),
                authFailure.failureLinkTitle && (React.createElement("p", { className: "authFailureLink", onClick: authFailure.failureLinkAction }, authFailure.failureLinkTitle))))));
};
