/**
 * Trace level.
 */
export var LogEntryLevel;
(function (LogEntryLevel) {
    /**
     * Custom events.
     */
    LogEntryLevel[LogEntryLevel["Custom"] = -2] = "Custom";
    /**
     * Debug level.
     */
    LogEntryLevel[LogEntryLevel["Debug"] = -1] = "Debug";
    /**
     * Verbose level.
     */
    LogEntryLevel[LogEntryLevel["Verbose"] = 0] = "Verbose";
    /**
     * Warning level.
     */
    LogEntryLevel[LogEntryLevel["Warning"] = 1] = "Warning";
    /**
     * Error level.
     */
    LogEntryLevel[LogEntryLevel["Error"] = 2] = "Error";
})(LogEntryLevel || (LogEntryLevel = {}));
