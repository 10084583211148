import * as React from "react";
import CollapseChevronDownIcon from "../../../../images/QueryBuilder/CollapseChevronDown_16x.png";
import { KeyCodes } from "../../../Common/Constants";
import { Action, ActionModifiers } from "../../../Shared/Telemetry/TelemetryConstants";
import * as TelemetryProcessor from "../../../Shared/Telemetry/TelemetryProcessor";
import * as StringUtils from "../../../Utils/StringUtils";
export class CommandButtonComponent extends React.Component {
    componentDidUpdate() {
        if (!this.dropdownElt || !this.expandButtonElt) {
            return;
        }
        $(this.dropdownElt).offset({ left: $(this.expandButtonElt).offset().left });
    }
    onKeyPress(event) {
        if (event.keyCode === KeyCodes.Space || event.keyCode === KeyCodes.Enter) {
            this.commandClickCallback && this.commandClickCallback(event);
            event.stopPropagation();
            return false;
        }
        return true;
    }
    onLauncherKeyDown(event) {
        if (event.keyCode === KeyCodes.DownArrow) {
            $(this.dropdownElt).hide();
            $(this.dropdownElt).show().focus();
            event.stopPropagation();
            return false;
        }
        if (event.keyCode === KeyCodes.UpArrow) {
            $(this.dropdownElt).hide();
            event.stopPropagation();
            return false;
        }
        return true;
    }
    getCommandButtonId() {
        if (this.props.id) {
            return this.props.id;
        }
        else {
            return `commandButton-${StringUtils.stripSpacesFromString(this.props.commandButtonLabel)}`;
        }
    }
    static renderButton(options, key) {
        return React.createElement(CommandButtonComponent, Object.assign({ key: key }, options));
    }
    commandClickCallback(e) {
        if (this.props.disabled) {
            return;
        }
        // TODO Query component's parent, not document
        const el = document.querySelector(".commandDropdownContainer");
        if (el) {
            el.style.display = "none";
        }
        this.props.onCommandClick(e);
        TelemetryProcessor.trace(Action.SelectItem, ActionModifiers.Mark, {
            commandButtonClicked: this.props.commandButtonLabel,
        });
    }
    renderChildren() {
        if (!this.props.children || this.props.children.length < 1) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("div", { className: "commandExpand", tabIndex: 0, ref: (ref) => {
                this.expandButtonElt = ref;
            }, onKeyDown: (e) => this.onLauncherKeyDown(e) },
            React.createElement("div", { className: "commandDropdownLauncher" },
                React.createElement("span", { className: "partialSplitter" }),
                React.createElement("span", { className: "expandDropdown" },
                    React.createElement("img", { src: CollapseChevronDownIcon }))),
            React.createElement("div", { className: "commandDropdownContainer", ref: (ref) => {
                    this.dropdownElt = ref;
                } },
                React.createElement("div", { className: "commandDropdown" }, this.props.children.map((c, index) => {
                    return CommandButtonComponent.renderButton(c, `${index}`);
                })))));
    }
    static renderLabel(props, key, refct) {
        if (!props.commandButtonLabel) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement("span", { className: "commandLabel", key: key, ref: refct }, props.commandButtonLabel));
    }
    render() {
        let mainClassName = "commandButtonComponent";
        if (this.props.disabled) {
            mainClassName += " commandDisabled";
        }
        if (this.props.isSelected) {
            mainClassName += " selectedButton";
        }
        let contentClassName = "commandContent";
        if (this.props.children && this.props.children.length > 0) {
            contentClassName += " hasHiddenItems";
        }
        return (React.createElement("div", { className: "commandButtonReact" },
            React.createElement("span", { className: mainClassName, role: "menuitem", tabIndex: this.props.tabIndex, onKeyPress: (e) => this.onKeyPress(e), title: this.props.tooltipText, id: this.getCommandButtonId(), "aria-disabled": this.props.disabled, "aria-haspopup": this.props.hasPopup, "aria-label": this.props.ariaLabel, onClick: (e) => this.commandClickCallback(e) },
                React.createElement("div", { className: contentClassName },
                    React.createElement("img", { className: "commandIcon", src: this.props.iconSrc, alt: this.props.iconAlt }),
                    CommandButtonComponent.renderLabel(this.props))),
            this.props.children && this.renderChildren()));
    }
}
