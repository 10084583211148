/**
 * Data Explorer tab kinds
 */
export var TabKind;
(function (TabKind) {
    TabKind[TabKind["SQLDocuments"] = 0] = "SQLDocuments";
    TabKind[TabKind["MongoDocuments"] = 1] = "MongoDocuments";
    TabKind[TabKind["SchemaAnalyzer"] = 2] = "SchemaAnalyzer";
    TabKind[TabKind["TableEntities"] = 3] = "TableEntities";
    TabKind[TabKind["Graph"] = 4] = "Graph";
    TabKind[TabKind["SQLQuery"] = 5] = "SQLQuery";
    TabKind[TabKind["ScaleSettings"] = 6] = "ScaleSettings";
})(TabKind || (TabKind = {}));
/**
 * Data Explorer pane kinds
 */
export var PaneKind;
(function (PaneKind) {
    PaneKind[PaneKind["AddCollection"] = 0] = "AddCollection";
    PaneKind[PaneKind["CassandraAddCollection"] = 1] = "CassandraAddCollection";
    PaneKind[PaneKind["DeleteCollection"] = 2] = "DeleteCollection";
    PaneKind[PaneKind["DeleteDatabase"] = 3] = "DeleteDatabase";
    PaneKind[PaneKind["GlobalSettings"] = 4] = "GlobalSettings";
    PaneKind[PaneKind["AdHocAccess"] = 5] = "AdHocAccess";
    PaneKind[PaneKind["SwitchDirectory"] = 6] = "SwitchDirectory";
})(PaneKind || (PaneKind = {}));
/**
 * The types of actions that the DataExplorer supports performing upon opening.
 */
export var ActionType;
(function (ActionType) {
    ActionType[ActionType["OpenTab"] = 0] = "OpenTab";
    ActionType[ActionType["OpenCollectionTab"] = 1] = "OpenCollectionTab";
    ActionType[ActionType["OpenPane"] = 2] = "OpenPane";
    ActionType[ActionType["TransmitCachedData"] = 3] = "TransmitCachedData";
    ActionType[ActionType["OpenSampleNotebook"] = 4] = "OpenSampleNotebook";
})(ActionType || (ActionType = {}));
