/**
 * Messaging types used with Data Explorer <-> Portal communication,
 * Hosted <-> Explorer communication
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * WARNING: !!!!!!! YOU CAN ONLY ADD NEW TYPES TO THE END OF THIS ENUM !!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Enum are integers, so inserting or deleting a type will break the communication.
 *
 */
export var MessageTypes;
(function (MessageTypes) {
    MessageTypes[MessageTypes["TelemetryInfo"] = 0] = "TelemetryInfo";
    MessageTypes[MessageTypes["LogInfo"] = 1] = "LogInfo";
    MessageTypes[MessageTypes["RefreshResources"] = 2] = "RefreshResources";
    MessageTypes[MessageTypes["AllDatabases"] = 3] = "AllDatabases";
    MessageTypes[MessageTypes["CollectionsForDatabase"] = 4] = "CollectionsForDatabase";
    MessageTypes[MessageTypes["RefreshOffers"] = 5] = "RefreshOffers";
    MessageTypes[MessageTypes["AllOffers"] = 6] = "AllOffers";
    MessageTypes[MessageTypes["UpdateLocationHash"] = 7] = "UpdateLocationHash";
    MessageTypes[MessageTypes["SingleOffer"] = 8] = "SingleOffer";
    MessageTypes[MessageTypes["RefreshOffer"] = 9] = "RefreshOffer";
    MessageTypes[MessageTypes["UpdateAccountName"] = 10] = "UpdateAccountName";
    MessageTypes[MessageTypes["ForbiddenError"] = 11] = "ForbiddenError";
    MessageTypes[MessageTypes["AadSignIn"] = 12] = "AadSignIn";
    MessageTypes[MessageTypes["GetAccessAadRequest"] = 13] = "GetAccessAadRequest";
    MessageTypes[MessageTypes["GetAccessAadResponse"] = 14] = "GetAccessAadResponse";
    MessageTypes[MessageTypes["UpdateAccountSwitch"] = 15] = "UpdateAccountSwitch";
    MessageTypes[MessageTypes["UpdateDirectoryControl"] = 16] = "UpdateDirectoryControl";
    MessageTypes[MessageTypes["SwitchAccount"] = 17] = "SwitchAccount";
    MessageTypes[MessageTypes["SendNotification"] = 18] = "SendNotification";
    MessageTypes[MessageTypes["ClearNotification"] = 19] = "ClearNotification";
    MessageTypes[MessageTypes["ExplorerClickEvent"] = 20] = "ExplorerClickEvent";
    MessageTypes[MessageTypes["LoadingStatus"] = 21] = "LoadingStatus";
    MessageTypes[MessageTypes["GetArcadiaToken"] = 22] = "GetArcadiaToken";
    MessageTypes[MessageTypes["CreateWorkspace"] = 23] = "CreateWorkspace";
    MessageTypes[MessageTypes["CreateSparkPool"] = 24] = "CreateSparkPool";
    MessageTypes[MessageTypes["RefreshDatabaseAccount"] = 25] = "RefreshDatabaseAccount";
    MessageTypes[MessageTypes["CloseTab"] = 26] = "CloseTab";
    MessageTypes[MessageTypes["OpenQuickstartBlade"] = 27] = "OpenQuickstartBlade";
    MessageTypes[MessageTypes["OpenPostgreSQLPasswordReset"] = 28] = "OpenPostgreSQLPasswordReset";
    MessageTypes[MessageTypes["OpenPostgresNetworkingBlade"] = 29] = "OpenPostgresNetworkingBlade";
    MessageTypes[MessageTypes["OpenCosmosDBNetworkingBlade"] = 30] = "OpenCosmosDBNetworkingBlade";
    MessageTypes[MessageTypes["DisplayNPSSurvey"] = 31] = "DisplayNPSSurvey";
    MessageTypes[MessageTypes["OpenVCoreMongoNetworkingBlade"] = 32] = "OpenVCoreMongoNetworkingBlade";
    MessageTypes[MessageTypes["OpenVCoreMongoConnectionStringsBlade"] = 33] = "OpenVCoreMongoConnectionStringsBlade";
    MessageTypes[MessageTypes["GetAuthorizationToken"] = 34] = "GetAuthorizationToken";
    MessageTypes[MessageTypes["GetAllResourceTokens"] = 35] = "GetAllResourceTokens";
    MessageTypes[MessageTypes["Ready"] = 36] = "Ready";
    MessageTypes[MessageTypes["OpenCESCVAFeedbackBlade"] = 37] = "OpenCESCVAFeedbackBlade";
    MessageTypes[MessageTypes["ActivateTab"] = 38] = "ActivateTab";
})(MessageTypes || (MessageTypes = {}));
