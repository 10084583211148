import { useEffect, useState } from "react";
import { configContext } from "../ConfigContext";
export async function fetchDirectories(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    let tenents = [];
    let nextLink = `${configContext.ARM_ENDPOINT}/tenants?api-version=2020-01-01`;
    while (nextLink) {
        const response = await fetch(nextLink, { headers });
        const result = response.status === 204 || response.status === 304 ? undefined : await response.json();
        if (!response.ok) {
            throw result;
        }
        nextLink = result.nextLink;
        tenents = [...tenents, ...result.value];
    }
    return tenents;
}
export function useDirectories(armToken) {
    const [state, setState] = useState();
    useEffect(() => {
        if (armToken) {
            fetchDirectories(armToken).then((response) => setState(response));
        }
    }, [armToken]);
    return state || [];
}
